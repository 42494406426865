@import "../../assets/scss/config.scss";

.navContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #020203;
  width: 100%;

  a {
    margin: 10px;
    text-decoration: none;
    color: #ffffff;
    font-size: 24px;
    cursor: pointer;
    font-family: "medium";
  }
  img {
    width: 178px;
    height: 60;
  }
}
