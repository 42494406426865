@import "../../assets/scss/config";

.homeContainer {
  margin-left: 226px;
  h1 {
    margin: 20px;
    padding: 10px;

    font-size: 48px;
    font-family: "bold";
    span {
      color: #ff0101;
    }
  }
  h2 {
    padding: 20px;
    font-size: 18px;
    font-family: "medium";
  }
  .ChartContainer {
    max-width: 835px;
    max-height: 320px;
    background-color: #fbfbfb;
  }
}
.containerNutritional {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sectionContainer {
  display: flex;
  flex-direction: column;
  left: 226px;
  justify-content: space-between;
  margin-right: 20px;
}

.LineContainer {
  background-color: #ff0000;
  height: 263px;
  width: 258px;

  border-radius: 5px;
}
.customContainer {
  display: flex;
  margin: 20px;
  padding: 20px;
}

.containerChardCustom {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
