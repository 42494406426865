@import "../../assets/scss/config.scss";

.sideBarContainer {
  position: absolute;
  height: calc(100vh - 80px);
  width: 117px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #020203;

  .LogoContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  img {
    margin-bottom: 20px;
  }

  .spacer {
    flex-grow: 1;
  }

  .copyright {
    position: relative;
    text-align: center;
    color: #ffffff;
    font-family: "medium";
    font-size: 12px;
    transform: rotate(270deg);
    bottom: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: auto;
    padding: 0;

    p {
      white-space: nowrap;
    }
  }
}
