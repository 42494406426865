@font-face {
    font-family: "Light";
    src: local("Roboto-Light"),
      url("../../assets/font/Roboto-Light.ttf") format("truetype");
  }
  @font-face {
    font-family: "Medium";
    src: local("Roboto-Medium"),
      url("../../assets/font/Roboto-Medium.ttf") format("truetype");
  }
  @font-face {
    font-family: "Bold";
    src: local("Roboto-Bold"),
      url("../../assets/font/Roboto-Bold.ttf") format("truetype");
  }
  
  /*|---------------| App Color Schema|---------------*/
  
  // $primary: #FF0101;
  // $secondary: #E5E5E5;
  // $dark: #000000;
  
  // $Bg-gradient: linear-gradient(
  //   to bottom,
  //   rgba(255, 255, 255, 0),
  //   rgba(0, 0, 0, 0.5)
  // );
  
  // /*|---------------| @media #{$BREAKPOINT-DESK}|---------------*/
  
  $BREAKPOINT-DESK: "only screen and (max-width : 1024px)";
  $BREAKPOINT-TABLETS: "only screen and (max-width : 768px)";
  $BREAKPOINT-MOBILE: "only screen and (max-width : 480px)";
  
  /*|---------------|MIXINS|---------------*/
  
//   @mixin button {
//     padding-left: 18.8px;
//     border-radius: 5px;
//     font-size: 24px;
//     font-weight: 500;
//     color: $secondary;
//     background-color: #ff6060;
//     }