.ErreurContainer {
  display: flex;
  align-items: center;
  flex-direction: column;

  h1 {
    margin-top: 100px;
    font-weight: 700;
    font-size: 288px;
  }

  p {
    font-size: 36px;
  }

  a {
    margin-top: 152px;
  }
}
