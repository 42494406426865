* {
  margin: 0;
  padding: 0;
  outline: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

// variables pour les dimensions de la page
$page-min-width: 1024px;

body {
  min-width: $page-min-width;
  margin: 0 auto;
  font-family: "Medium";
}
