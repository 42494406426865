@import "../../assets/scss/config.scss";

.CustomPie {
  background: #fbfbfb;
}
.chartPieLabel {
  position: absolute;
  top: 24px;
  left: 20px;
  z-index: 1;
  font-size: 15px;
}

.textCenter {
  display: flex;
  flex-direction: column;
  align-items: center;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 20;

  .percent {
    font-size: 26px;
    font-family: bold;
  }

  .textPercent {
    width: 50%;
  }
}
