@import "../../assets/scss/config.scss";

.InfoNutritional {
  display: flex;
  width: 258px;
  height: 124px;
  background-color: #FBFBFB;
  padding-left: 32px;
  
  align-items: center;
  .uniteContainer{
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    margin-left: 20px;
    
    .title {
      font-size: 14px;
      color: #74798C;
    }
    .value{
      font-size: 20px;
      color: #282d30;
      font-weight: 700;
      padding: 2px;
    }
  }
}
img {
  display: flex;
  width: 60px;
  height: 60px;
  
}
