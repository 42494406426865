.CustomRadar {
  background: #282d30;
  border-radius: 5px;
}

.recharts-polar-angle-axis-tick-value {
  transform: translateX(-3px);
  transform: translatey(3px);
  
}
